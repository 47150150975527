import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { WebapiService } from '../webapi.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DomSanitizer } from '@angular/platform-browser';



@Component({
  selector: 'app-checks',
  templateUrl: './checks.component.html',
  styleUrls: ['./checks.component.scss']
})
export class ChecksComponent implements OnInit {
   filter:any={};
  total: any;
  p:number = 1;
  checkList: any;
  checkListV2: any;
  countList:any= {};
  loginType:any;
  teamName:any
  openCounter: boolean;
  multiple:any=[];
  proofMultiple:any=[];
  imageArr:any=[];
  imageUrl: any;
  statusArr: any =[];
  finalStatusArr: any=[];
  filterData:any={}
  clientList: any;
  openProofCounter: boolean;
  proofData:any={};
  imageProofArr=[];
  loginId: any;
  fileString: any;
  pdfCounter: boolean = false;
  imageCounter: boolean = false;
  constructor(private WebApiService:WebapiService,private activatedRoute:ActivatedRoute,
    private router:Router,private modalService: NgbModal,private sanitizer: DomSanitizer) {
    this.statusArr = ['DRAFT','NEW','INPROGRESS','HOLD','COMPLETED','STOPPED'];
      this.imageUrl = this.WebApiService.imageUrl;
     }
    applicationList: any;
    candidate: any;

    ngOnInit(): void {
      this.loginId = sessionStorage.getItem('loginId');
      this.loginType = sessionStorage.getItem('type');
      this.teamName = sessionStorage.getItem('teamName');
      this.multiple = [
        {
          file:""
        }
      ]
      this.proofMultiple = [
        {
          file:""
        }
      ]
      this.filter = {
        pageNo:1,
        size:10,
        status:'',
        search:'',
        clientId:'',
        created:''
      }
      if(this.loginType == 1){
        this.getAllApplication();
        this.getApplications();
      }
      else if(this.loginType == 4 && this.teamName == "Case Initiation Team"){
        this.getAllApplicationV2();
        this.getApplicationsInit();

      }
      else if(this.loginType ==4 && this.teamName == "Processing Team"){
        this.getAllApplicationByEmp();
        this.getApplicationsEmp();

      }
      else if(this.loginType == 3){
        this.filter.clientId = this.loginId;
        this.getAllApplication();
        this.getApplications();
      }
      this.getClient();

    }

    getAllApplication(){
      this.WebApiService.getAllApplication(this.filter).subscribe((res:any)=>{
        this.applicationList = res.data;
        this.total = res.total;
        let arr =[];
        for(let key of this.applicationList){
          for (const it of key.checksData) {
            it.appData = key;
            arr.push(it);
          }
        }
        this.checkList = arr;
        console.log(this.checkList,'find the check list data---------------');
        
        this.getAppById(this.applicationList[0]._id,this.applicationList[0].checksData[0].checkName);
      })
    }

    getAllApplicationV2(){
      this.WebApiService.getAllApplicationInit(this.filter).subscribe((res:any)=>{
      //  console.log(res);
      this.applicationList = res.data;
      this.total = res.total;
      let arr =[];
      for(let key of this.applicationList){
        for (const it of key.checksData) {
          it.appData = key;
          arr.push(it);
        }
      }
      this.checkList = arr;
      this.getAppById(this.applicationList[0]._id,this.applicationList[0].checksData[0].checkName);

      })
    }

    getClient() {
      this.WebApiService.getAllClient().subscribe((res: any) => {
        this.clientList = res.user;
      });
    }

  getAllApplicationByEmp(){
    this.WebApiService.getAllApplicationByEmp(this.filter).subscribe((res:any)=>{
      this.applicationList = res.data;
      this.total = res.total;
      let arr =[];
      for(let key of this.applicationList){
        for (const it of key.checksData) {
          it.appData = key;
          arr.push(it);
        }
      }
      this.checkList = arr;
      this.getAppById(this.applicationList[0]._id,this.applicationList[0].checksData[0].checkName);
    })
  }

    getStatusWiseCheck(status:any,app:any){
      let arr =[];
      for(let key of app){
       for (const it of key.checksData) {
         it.appData = key;
         arr.push(it);
       }

      }
      const data = arr.filter(x=>x.status == status);
      // console.log(data,'---------');
      this.checkList = data;

    }

    setStatus(e:any,data:any,checkName:any,id:any){
      let index = data.findIndex(x=>x.checkName == checkName);
      if(index >= 0){
        data[index].status = e.target.value;
      }

      let obj = {};
      let arr = data.slice();
      obj['_id'] = id;
      for (const it of arr) {
        delete it.appData
      }
      obj['checksData'] = arr;
      this.WebApiService.insertApplication(obj).subscribe((res: any) => {
      // console.log(res);
      if(res.status == "ok"){
        alert(res.msg);
        if(this.loginType == 1){
          this.getAllApplication();
        }
        if(this.loginType == 4 && this.teamName == "Case Initiation Team"){
          this.getAllApplicationV2();

        }
        if(this.loginType == 4){
          this.getAllApplicationByEmp();
        }
      }
      else{
        alert(res.msg);
      }
      })
    }

    getAppById(id:any,checkName:any){
      this.WebApiService.getAppById(id).subscribe((res:any)=>{
        //  console.log(res,'--');
       this.candidate = res;
       let arr =[];
        for (const it of this.candidate.checksData) {
          it.appData = this.candidate;
          if(it.checkName == checkName){
            arr.push(it);
          }
        }
       this.checkListV2 = arr;
       console.log(this.checkListV2,'--');

       let shCopy = this.statusArr.slice();
       let index = shCopy.indexOf(this.checkListV2[0]?.status);
       if(index >= 0){
        shCopy.splice(index, 1)
        this.finalStatusArr = shCopy;
       }
       else{
        this.finalStatusArr = this.statusArr;
       }

        })
    }

    onTableDataChange(event: any) {
      this.filter.pageNo = event;
      if(this.loginType == 1){
        this.getAllApplication();
      }
      if(this.loginType == 4 && this.teamName == "Case Initiation Team"){
        this.getAllApplicationV2();
      }
      if(this.loginType == 4){
        this.getAllApplicationByEmp();
      }
      this.p = event;
    //   window.scrollTo({
    //    top: 0,
    //    behavior: 'smooth'
    //  });
   }

   openForm(){
    this.openCounter = true;
   }
   saveData(data:any,checkName:any){
    // console.log(data);
    // return
    if(data[0]?.checkDoc && data[0]?.checkDoc.length > 0){
      data[0].checkDoc = [...data[0]?.checkDoc,...this.imageArr];
    }
    else{
      data[0].checkDoc = this.imageArr;
    }
     let obj = {};
     let arr = [...data[0].appData.checksData];
     obj['_id'] = data[0].appData._id;
     for (const it of arr) {
       delete it.appData
     }
     obj['checksData'] = arr;
     this.WebApiService.insertApplication(obj).subscribe((res: any) => {
      // console.log(res);
      if(res.status == "ok"){
        alert(res.msg);
        if(this.loginType == 1){
          this.getAllApplication();
          this.openCounter = false;
        }
        if(this.loginType == 4 && this.teamName == "Case Initiation Team"){
          this.getAllApplicationV2();
          this.openCounter = false;

        }
        if(this.loginType == 4){
          this.getAllApplicationByEmp();
          this.openCounter = false;

        }
        this.imageArr = [];
      }
      else{
        alert(res.msg);
      }

    });
   }
   closeForm(){
    this.openCounter = false;
   }

   getApplications(){
    this.WebApiService.getApplications(this.filter).subscribe((res:any)=>{
    // console.log(res);
    let arr =[];
    for(let key of res.data){
     for (const it of key.checksData) {
       it.appData = key;
       arr.push(it);
     }
    }
    // console.log(arr,'---------');

    const newCount = arr.filter(x=>x.status == "NEW")
    const draftCount = arr.filter(x=>x.status == "DRAFT")
    const inprogressCount = arr.filter(x=>x.status == "INPROGRESS")
    const completedCount = arr.filter(x=>x.status == "COMPLETED")
    const stoppedCount = arr.filter(x=>x.status == "STOPPED")
    const holdCount = arr.filter(x=>x.status == "HOLD")

    this.countList = {
      all:arr.length,
      new:newCount.length,
      draft:draftCount.length,
      inprogress:inprogressCount.length,
      completed:completedCount.length,
      stopped:stoppedCount.length,
      hold:holdCount.length
    }
    })
  }

  getApplicationsInit(){
    this.WebApiService.getApplicationsInit(this.filter).subscribe((res:any)=>{
    // console.log(res);
    let arr =[];
    for(let key of res.data){
     for (const it of key.checksData) {
       it.appData = key;
       arr.push(it);
     }
    }
    // console.log(arr,'---------');

    const newCount = arr.filter(x=>x.status == "NEW")
    const draftCount = arr.filter(x=>x.status == "DRAFT")
    const inprogressCount = arr.filter(x=>x.status == "INPROGRESS")
    const completedCount = arr.filter(x=>x.status == "COMPLETED")
    const stoppedCount = arr.filter(x=>x.status == "STOPPED")
    const holdCount = arr.filter(x=>x.status == "HOLD")

    this.countList = {
      all:arr.length,
      new:newCount.length,
      draft:draftCount.length,
      inprogress:inprogressCount.length,
      completed:completedCount.length,
      stopped:stoppedCount.length,
      hold:holdCount.length
    }
    })
  }

  getApplicationsEmp(){
    this.WebApiService.getApplicationsEmp(this.filter).subscribe((res:any)=>{
    let arr =[];
    for(let key of res.data){
     for (const it of key.checksData) {
       it.appData = key;
       arr.push(it);
     }
    }

    const newCount = arr.filter(x=>x.status == "NEW")
    const draftCount = arr.filter(x=>x.status == "DRAFT")
    const inprogressCount = arr.filter(x=>x.status == "INPROGRESS")
    const completedCount = arr.filter(x=>x.status == "COMPLETED")
    const stoppedCount = arr.filter(x=>x.status == "STOPPED")
    const holdCount = arr.filter(x=>x.status == "HOLD")

    this.countList = {
      all:arr.length,
      new:newCount.length,
      draft:draftCount.length,
      inprogress:inprogressCount.length,
      completed:completedCount.length,
      stopped:stoppedCount.length,
      hold:holdCount.length
    }
    })
  }

  uploadDocs(e:any,check:any){
    const formData = new FormData();
    formData.append('image',e.target.files[0]);
    formData.append('type','return');
    this.WebApiService.uploadImage(formData).subscribe((res: any) => {
       if(res.status == "ok"){
         alert('Uploaded file successfully');
         this.imageArr.push(res.imageName);
        }
        else{
         alert("something went wrong")
        }
     });
  }

  searchCheck(e:any){
    this.filter.search = e.target.value
    if(e.target.value){
     this.WebApiService.getCheckBySearch(this.filter).subscribe((res:any)=>{
      console.log(res.total[0].total,'---');

      this.applicationList = res.data;
      this.total = res.total;
      let arr =[];
      for(let key of this.applicationList){
        for (const it of key.checksData) {
          it.appData = key;
          arr.push(it);
        }
      }
      this.checkList = arr;
      this.getAppById(this.applicationList[0]._id,this.applicationList[0].checksData[0].checkName);
         const newCount = arr.filter(x=>x.status == "NEW")
         const draftCount = arr.filter(x=>x.status == "DRAFT")
         const inprogressCount = arr.filter(x=>x.status == "INPROGRESS")
         const completedCount = arr.filter(x=>x.status == "COMPLETED")
         const stoppedCount = arr.filter(x=>x.status == "STOPPED")
         const holdCount = arr.filter(x=>x.status == "HOLD")

         this.countList = {
           all:res.total[0].total,
           new:newCount.length,
           draft:draftCount.length,
           inprogress:inprogressCount.length,
           completed:completedCount.length,
           stopped:stoppedCount.length,
           hold:holdCount.length
         }
       })
  }
  else{
    if(this.loginType == 1){
      this.getAllApplication();
      this.getApplications();
    }
    else if(this.loginType == 4 && this.teamName == "Case Initiation Team"){
      this.getAllApplicationV2();
      this.getApplicationsInit();

    }
    else if(this.loginType ==4 && this.teamName == "Processing Team"){
      this.getAllApplicationByEmp();
      this.getApplicationsEmp();

    }
  }
}

  applyFilter(data:any){
    this.filter.clientId = data.clientId;
    this.filter.created = data.created;
    this.WebApiService.getCheckByFilter(this.filter).subscribe((res:any)=>{
      this.applicationList = res.data;
      this.total = res.total;
     if(res.data.length > 0){
      let arr =[];
      for(let key of this.applicationList){
        for (const it of key.checksData) {
          it.appData = key;
          arr.push(it);
        }
      }
      this.checkList = arr;
      this.getAppById(this.applicationList[0]._id,this.applicationList[0].checksData[0].checkName);
         const newCount = arr.filter(x=>x.status == "NEW")
         const draftCount = arr.filter(x=>x.status == "DRAFT")
         const inprogressCount = arr.filter(x=>x.status == "INPROGRESS")
         const completedCount = arr.filter(x=>x.status == "COMPLETED")
         const stoppedCount = arr.filter(x=>x.status == "STOPPED")
         const holdCount = arr.filter(x=>x.status == "HOLD")

         this.countList = {
           all:res.total,
           new:newCount.length,
           draft:draftCount.length,
           inprogress:inprogressCount.length,
           completed:completedCount.length,
           stopped:stoppedCount.length,
           hold:holdCount.length
         }
     }
     else{
      this.checkList = [];
      this.checkListV2 = [];
        this.countList = {
        all:0,
        new:0,
        draft:0,
        inprogress:0,
        completed:0,
        stopped:0,
        hold:0
      }
     }
    })
  }

  addMoreUpload(){
    this.multiple.push({});
  }
  addMoreProofUpload(){
    this.proofMultiple.push({});
  }

  resetFilter(){
    this.filterData = {};
  }

  editProof(data:any){
    console.log(data,'---');
    if(data[0].proof){
      this.proofData = data[0]?.proof;
    }
    this.openProofCounter = true;
  }

  uploadProofDocs(e:any,check:any){
    const formData = new FormData();
    formData.append('image',e.target.files[0]);
    formData.append('type','return');
    this.WebApiService.uploadImage(formData).subscribe((res: any) => {
       if(res.status == "ok"){
         alert('Uploaded file successfully');
         this.imageProofArr.push(res.imageName);
        }
        else{
         alert("something went wrong")
        }
     });
  }

  saveProofData(data:any,objdata:any,checkname:any){
   data[0].proof = objdata;
   if(data[0]?.proof?.doc && data[0]?.proof?.doc.length > 0){
    data[0].proof.doc = [...data[0]?.proof.doc,...this.imageProofArr];
  }
  else{
    data[0].proof.doc = this.imageProofArr;
  }

   let obj = {};
   let arr = [...data[0].appData.checksData];
   obj['_id'] = data[0].appData._id;
   for (const it of arr) {
     delete it.appData
   }
   obj['checksData'] = arr;
  //  console.log(data);
  //  return
   this.WebApiService.insertApplication(obj).subscribe((res: any) => {
    // console.log(res);
    if(res.status == "ok"){
      alert(res.msg);
      if(this.loginType == 1){
        this.getAllApplication();
        this.openProofCounter = false;
      }
      if(this.loginType == 4 && this.teamName == "Case Initiation Team"){
        this.getAllApplicationV2();
        this.openProofCounter = false;

      }
      if(this.loginType == 4){
        this.getAllApplicationByEmp();
        this.openProofCounter = false;

      }
      this.imageProofArr = [];
    }
    else{
      alert(res.msg);
    }

  });
  }
  closeProofForm(){
    this.openProofCounter = false;
  }

  getImage(name:any,mdl:any){
    this.modalService.open( mdl, { size : 'lg' } );
    let url = `${this.imageUrl}${name}`;
    // console.log(name.includes('.png'));
    if(name.includes('.pdf')){
      this.pdfCounter = true;
      this.imageCounter = false;
      this.fileString = this.sanitizer.bypassSecurityTrustResourceUrl(url);
    }
    else if(name.includes('.jpg') || name.includes('JPG') || name.includes('.png') || name.includes('.PNG') ){
      this.imageCounter = true;
      this.pdfCounter = false
      this.fileString = url;
    }

  }

  removeDocCheck(img:any,index:any,data:any){
    let conf = confirm("Are you sure you want to delete")

    if(conf){
      // console.log(data[0].checkDoc,'--');
       data[0].checkDoc.splice(index,1);
      let obj = {};
      let arr = [...data[0].appData.checksData];
      // console.log(arr,'--=-=-=');
      obj['_id'] = data[0].appData._id;
      for (const it of arr) {
        delete it.appData
      }
      obj['checksData'] = arr;
      this.WebApiService.insertApplication(obj).subscribe((res: any) => {
       // console.log(res);
       if(res.status == "ok"){
         alert(res.msg);
       }
       else{
         alert(res.msg);
       }

     });
    }

  }

  removeDocProofCheck(img:any,index:any,data:any){
    let conf = confirm("Are you sure you want to delete")

    if(conf){
      // console.log(data[0].checkDoc,'--');
       data[0].proof.doc.splice(index,1);
      let obj = {};
      let arr = [...data[0].appData.checksData];
      // console.log(arr,'--=-=-=');
      // return
      obj['_id'] = data[0].appData._id;
      for (const it of arr) {
        delete it.appData
      }
      obj['checksData'] = arr;
      this.WebApiService.insertApplication(obj).subscribe((res: any) => {
       // console.log(res);
       if(res.status == "ok"){
         alert(res.msg);
       }
       else{
         alert(res.msg);
       }

     });
    }



  }

}
